/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 16px;
    font-family: $opensans; 
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 16px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: $opensans;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1, h2, h3, h4, h5, h6{line-height: 1.3;}
h1 { 
    font-size: 61px; font-weight: 300;
    &.h1s2{font-size: 100px; font-weight: 700;}
}
h2 {
    font-size: 48px; font-weight: 300;
    &.h2s2{font-size: 39px;}
}
h3 {
    font-size: 31px; font-weight: 700;
    &.h3s2{font-size: 38px;}
    &.h3s3{font-size: 42px;}
}
h4 { font-size: 28px; } 
h5 { font-size: 23px; font-weight: 900;} 
h6 { font-size: 19px; font-weight: 700;}
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1370px;margin: 0 auto;}
.wrap-md {width: 1230px;margin: 0 auto;}
.wrap-md2 {width: 1170px;margin: 0 auto;}
.wrap-sm {
    width: 1030px;margin: 0 auto;
    &.sidepadd{@include boxSizing(border-box); padding: 0 30px;}
}
.wrap-sm2{width: 900px;margin: 0 auto;}
.wrap-xs{width: 800px; margin: 0 auto;}

.wrapper, .wrap-md, .wrap-md2, .wrap-sm, .wrap-sm2, .wrap-xs{@include afterboth;}

.btn-rounded{
    display: inline-block; position: relative; @include borderRadius(10px); background: $orange; color: $white; font-weight: 700; height: 50px; line-height: 44px; padding: 0 20px; 
    border: 3px solid $orange; @include transition(0.2s all ease-out); cursor: pointer;@include boxSizing(border-box); 
    &:hover{background: transparent; color: $orange;}

    &.btn-lnblue{
        background: transparent; border-color: $blue; color: $blue;
        &:hover{background: $blue; color: $white;}
    }
    &.btn-blue{
        background: $blue; border-color: $blue; color: $white;
        &:hover{background: transparent; color: $blue;}
    }
    &.btn-md{height: 40px; line-height: 34px; font-size: 13px;}
}
.headpadd{margin-top: 140px;}
.lineh{display: inline-block; width: 40px; height: 4px; background: $blue;}

.icwp{
    display: inline-block; width: 24px; height: 24px; background-repeat: no-repeat; background-position: top center;
    &:hover{background-position: bottom center;}

    &.ic_bed{background-image: url('../images/material/ic_bed.png'); width: 55px; height: 49px;}
    &.ic_wheelchair{background-image: url('../images/material/ic_wheelchair.png');width: 55px; height: 49px;}
    &.ic_head{background-image: url('../images/material/ic_head.png'); width: 37px; height: 39px;}
    &.ic_checkup{background-image: url('../images/material/ic_checkup.png'); width: 37px; height: 39px;}
    &.ic_tooth{background-image: url('../images/material/ic_tooth.png'); width: 37px; height: 39px;}
}

.a-center{text-align: center;}
.a-left{text-align: left;}
.a-right{text-align: right;}
.link-orange{font-weight: 700; text-transform: uppercase; color: $orange;}
.tblue{color: $blue;}
.torange{color: $orange;}
.link-load{
    color: $orange; font-weight: 900; font-size: 14px; text-transform: uppercase; position: relative; padding-left: 50px; line-height: 27px; height: 27px; display: inline-block;
    &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; width: 27px; height: 27px; background: url('../images/material/ic_load.png') no-repeat center;}
}
.link-plus{
    position: relative; color: $blue; font-weight: 700; text-transform: uppercase; padding-left: 26px; font-size: 14px;
    &:before{content: ""; width: 15px; height: 15px; background: url('../images/material/plusorange.png') no-repeat center; background-size: 100%; position: absolute; top: 2px; left: 0;}
}

.row_list{
    margin-left: -15px; margin-right: -15px; @include boxSizing(border-box);
    *{@include boxSizing(border-box);}
    &:after{content: ""; display: table; clear: both;}
    .col{
        width: 100%; padding: 0 15px; float: left;
        &.fr{float: right;}
        &.no-pad{padding: 0;}
        &.marg28{margin-bottom: 28px;}
        &.col_9{width: 90%;}
        &.col_8{width: 80%;}
        &.col_7{width: 70%;}
        &.col_66{width: 66.66666666666666%;}
        &.col_65{width: 65%;}
        &.col_6{width: 60%;}
        &.col_55{width: 55%;}
        &.col_5{width: 50%;}
        &.col_45{width: 45%;}
        &.col_4{width: 40%;}
        &.col_37{width: 37%;}
        &.col_35{width: 35%;}
        &.col_33{width: 33.3333333333333%;}
        &.col_3{width: 30%;}
        &.col_25{width: 25%;}
        &.col_2{width: 20%;}
        &.col_16{width: 16.66666666666666%;}
        &.col_1{width: 10%;}
    }
    &.center{
        text-align: center;
        .col{float: none; display: inline-block;}
    }
    &.listfull{
        margin-left: 0; margin-right: 0;
        .col{padding: 0;}
    }
    &.listp5{
        margin-left: -5px; margin-right: -5px;
        .col{padding: 0 5px;}
    }
}


/* header
----------------------------------------------------------------------------------------------*/
header{
    position: fixed; top: 0; left: 0; right: 0; z-index: 1000; background: $white;
    .logo{float: left; padding: 20px 0 18px;}
    .head-right{float: right; padding-top: 52px;}
    .navigation{
        float: left; padding-right: 15px;
        /*&:before{content: ""; position: absolute; top: 0; right: 0; bottom: 0; width: 1px; background: #eaeaea;}*/
        > li{
            float: left; 
            > a{
                color: #333333; display: block; padding: 12px 15px 60px;position: relative; cursor: pointer;
                &:before{content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 0; background: $blue; @include transition(0.2s all ease-out);}
            }
            .subnav{
                position: absolute; top: 100%; left: 0; right: 0; opacity: 0; visibility: hidden; @include transition(0.3s all ease-out);
                /*&:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); height: 100vh;}*/

                .inmenu{
                    background: $white; width: 1370px; margin: 0 auto; padding: 40px 100px 60px; @include boxSizing(border-box);  position: absolute; top: 0; left: 0; right: 0;
                    &:after{content: ""; position: absolute; top: 0; left: 0; right: 0; height: 1px; background: #eaeaea;}
                    .lsinmenu{float: left; width: 33.3333333333333333%; padding: 0 15px; @include boxSizing(border-box);}
                    a{
                        font-size: 20px; color: #333333; line-height: 26px; @include transition(0.2s all ease-out);
                        &:hover, &.active{color: $orange;}
                    }
                    ul{
                        li{
                            margin-bottom: 10px; position: relative; padding-left: 24px;
                            &:before{content: ""; position: absolute; top: 6px; left: 0; background: url('../images/material/right-arrow.png') no-repeat center; width: 8px; height: 12px;}
                        }
                    }
                }
            }
            
            &:hover{
                .subnav{opacity: 1; visibility: visible;}
            }
            &:hover, &.active{
                > a{
                    &:before{height: 4px;}
                }
            }
            &.active{
                > a{font-weight: 700;}
            }
        }
        .btn-rounded{display: none;}
    }
    .nav-lang{
        float: left; position: relative; margin-right: 31px;
        .btn-lang{
            padding:15px 30px; font-weight: 700; color: $blue; font-size: 13px; position: relative; cursor: pointer;
            &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; width: 1px; background: #eaeaea;}
            &:after{content: ""; position: absolute; top: 0; right: 0; bottom: 0; width: 1px; background: #eaeaea;}
        }
        .drop-lang{
            position: absolute; top: 100%; left: 0; right: 0; padding-top: 3px; padding-bottom: 3px; background: $white; display: none;@include borderRadius(0 0 5px 5px); 
            li{
                font-size: 13px; text-align: center; padding: 5px; font-weight: 700; border-bottom: 1px solid #f5f5f5;
                &:last-child{border-bottom: none;}
                a{color: $blue;}
            }
        }
    }
    .btn-rounded{margin-top: -5px;}
    .submenu{
        position: absolute; top: 100%; left: 0; right: 0; display: none;
        &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); height: 100vh;}
        &:after{content: ""; position: absolute; top: 0; left: 0; right: 0; height: 1px; background: #eaeaea;}
        a{
            font-size: 20px; color: #333333; line-height: 26px; @include transition(0.2s all ease-out);
            &:hover{color: $orange;}
        }
        ul{
            li{
                margin-bottom: 10px; position: relative; padding-left: 24px;
                &:before{content: ""; position: absolute; top: 6px; left: 0; background: url('../images/material/right-arrow.png') no-repeat center; width: 8px; height: 12px;}
            }
        }
        .lsmenu{
            background: $white; width: 1370px; margin: 0 auto; padding: 40px 100px 60px; @include boxSizing(border-box);  position: absolute; top: 0; left: 0; right: 0;
            &.active{display: block;}
        }
        .inmenu{
            margin: 0 -15px;
            @include afterboth;
            .lsinmenu{float: left; width: 33.3333333333333333%; padding: 0 15px; @include boxSizing(border-box);}
        }
    }
    .btn-burgmenu{display: none;}
    .box-sosmed{display: none;}
}

/* middle
----------------------------------------------------------------------------------------------*/
.box-slidehome{
    position: relative; border-bottom: 1px solid $white;
    .tgslider{position: absolute; bottom: 20px;}
    .box-filter{width: 1370px; margin: 0 auto; position: absolute; bottom: -70px; left: 0; right: 0;}
}
.slidehome{
    .list{
        position: relative;
        h1{margin-bottom: 32px;}
        h5{font-weight: 900; text-transform: uppercase; margin-bottom: 15px;}
        .lineh{margin-bottom: 25px;}
        .desc{
            position: absolute; width: 1370px; left: 0; right: 0; margin: 0 auto; top: 50%; @include transform(translateY(-50%));
        }
    }
    .slick-dots{
        position: absolute; bottom: 103px; left: 50%; @include transform(translateX(-50%));
        li{
            float: left; margin: 0 12px;
            button{font-size: 0; color: transparent; @include borderRadius(10px); border: 3px solid $blue; width: 17px; height: 17px; padding: 0; background: $blue; cursor: pointer;}

            &.slick-active{
                button{background: $white;}
            }
        }
    }
}
.box-form{
    label{font-weight: 700; color: #999999; font-size: 13px; text-transform: uppercase; display: block; margin-bottom: 7px;}
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number],
    textarea{
        background: transparent; border: 1px solid $blue; @include borderRadius(10px); padding: 0 13px; height: 50px; line-height: 50px;@include boxSizing(border-box); width: 100%;
        @include andplaceholder{color: #cccccc;}
    }
    textarea{resize: none; height: 107px;}

    .row_list, &.row_list{
        .col{margin-bottom: 45px;}
    }
}

.box-radio-button{
    padding-top: 10px;
    .radio-custom{
        float: left; margin-right: 35px;
    }
}
.radio-custom{
    position: relative; padding-left:30px; margin: 0; cursor: pointer; font-weight: 400 !important; font-size: 16px !important; color: #333 !important; text-transform: none !important;
    input[type=radio]{
        background: url(../images/material/ic_radio.png) no-repeat top center; display: inline-block; vertical-align: middle; margin:0; padding: 0; width:14px; height:14px; position:absolute; top:0; left:0; cursor: pointer;
        &:checked{background-position: bottom center;}
    }
}
.textstyle{
    position: relative;
    &:before{content: ""; position: absolute; top: 0; right: 0; bottom: 0; width: 40px; background: url('../images/material/ic_date.png') no-repeat center left;}
    input[type=text]{padding-right: 50px;}
}
.box-filter{
    background: $white; @include borderRadius(10px);position: relative; padding: 40px 70px; z-index: 2; @include boxSizing(border-box);
    @include afterboth; @include boxShadow(0 5px 40px -10px rgba(0,0,0,0.2));
    label{font-weight: 700; color: #999999; font-size: 13px; text-transform: uppercase; display: block; margin-bottom: 7px;}
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number],
    textarea{
        background: #f5f5f5; border: none; @include borderRadius(10px); padding: 0 13px; height: 50px; line-height: 50px;@include boxSizing(border-box); width: 100%; min-width: 220px;
        @include andplaceholder{color: #cccccc;}
    }
    .left{
        width: 200px; margin-right: 30px;
        h3{max-height: 80px; overflow: hidden;}
    }
    .center{
        float: left;
    }
    .right{
        position: absolute; top: 0; right: 0; bottom: 0;background: url('../images/material/bg-buttonfilter.png') no-repeat center; background-size: 100% 100%;
        input[type=submit]{
            width: 117px; height: 100%; border: none; background: url('../images/material/arr-right.png') no-repeat 50% center; cursor: pointer; @include transition(0.2s all ease-out); font-size: 0px; color: transparent;
            &:hover{background-position: 55% center;}
        }
    }
    .selectcustom{
        .btnselect{width: 230px;}
    }
}
.row{
    float: left; margin-right: 10px;
    &:last-child{margin-right: 0;}
}
.selectcustom{
    position: relative;
    .btnselect{
        background: #f5f5f5 url('../images/material/arr-select.png') no-repeat center right; font-size: 16px; color: #333; padding:0 50px 0 13px; height: 50px; line-height: 50px; @include borderRadius(10px); 
        min-width: 220px; max-width: 100%; @include boxSizing(border-box); cursor: pointer; color: #cccccc; overflow: hidden;
        &.med{min-width: 160px;}
        &.active{color: #333;}
    }
    .dropselect{
        position: absolute; top: 100%; left: 0; right: 0; background: #f5f5f5; z-index: 100; @include borderRadius(10px); padding: 10px 5px; max-height: 200px; overflow: auto; display: none;
        .lsdropselect{
            padding: 10px 13px; cursor: pointer;
            &:hover, &:focus, &:active{background: $white;}
        }
    }

    &.strans{
        .btnselect{background-color: transparent; border: 1px solid $blue;}
    }
}

.sec-service{
    background: #f7ede3; position: relative;
    .tgservicelist{position: absolute; top: 80%;}
    .in-service{
        width: 970px; margin: 0 auto; text-align: center;
        @include afterboth;
        h2{margin-bottom: 25px;}
        h5{margin-bottom: 20px; text-transform: uppercase;}
        p{line-height: 28px;}
        .lineh{margin-bottom: 15px;}
    }
    .top-service{
        background: url('../images/material/bg-service.jpg') no-repeat center; background-size: cover; padding: 165px 0 110px; position: relative;
        .tgservice{position: absolute; top: 50%;}
    }
}

.servicelist{
    @include afterboth;
    .ls-service{
        float: left; width: 33.333333333333333%; @include boxSizing(border-box);position: relative; cursor: pointer; display: block; overflow: hidden;
        &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; right: 0;z-index: 2; background: rgba(191,138,93,0.4); opacity: 0; visibility: hidden; @include transition(0.3s all ease-out);}
        &:after{content: ""; position: absolute; top: -35px; left: 0; right: 0; width: 0; height: 0; border-top: 25px solid #f7ede3; border-left: 35px solid transparent; border-right: 35px solid transparent; margin: auto; opacity: 0; z-index: 4;@include transition(0.3s all ease-out);}
        h5{text-transform: uppercase; font-size: 28px; text-transform: uppercase; margin-bottom: 15px;}
        p{line-height: 23px; height: 46px; overflow: hidden;}
        figure{
            position: relative;
            &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: url('../images/material/bg-btmservice.png') no-repeat bottom center; background-size: 100%; z-index: 1;}
        }
        .icoservice{position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; width: 110px; height: 122px; z-index: 10;}
        .desc{position: absolute; bottom: 0; left: 0; right: 0; padding: 0 80px 65px;z-index: 3; color: $white; text-align: center;}

        &:hover{
            &:before{opacity: 1; visibility: visible;}
            &:after{opacity: 1; top: 0;}
        }
    }
}

.sec-fasility{
    padding: 105px 0 178px; background: url('../images/material/bg-facility.jpg') no-repeat center; background-size: cover; position: relative;
    .tgfacility{position: absolute; top: 20%;}
    .ttl-fasility{
        text-align: center;
        h2{margin-bottom: 65px;}
        h5{margin-bottom: 20px; text-transform: uppercase;}
        .lineh{margin-bottom: 15px;}
    }
}

.btn-tab{
    margin-bottom: 75px;
    &.center{text-align: center;}
    .lsbtn-tab{
        display: inline-block; margin-right: 95px; font-weight: 900; text-transform: uppercase; color: #666666; font-size: 16px; cursor: pointer; position: relative; padding-bottom: 20px;
        &:before{content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 4px; background: $orange; width: 0px; margin: 0 auto;@include transition(0.2s all ease-out);}
        .icwp{vertical-align: middle; margin-right: 12px; margin-top: -17px;}

        &:last-child{margin-right: 0;}
        &:hover, &.active{
            &:before{width: 100%;}
            .icwp{background-position: bottom center;}
        }
    }
    &.lg{
        .lsbtn-tab{font-size: 20px;}
    }
}

.ctn-tab{
    @include afterboth;
    .ls-tab{
        display: none;
        &.active{display: block;}
    }
}

.sidefasility{
    .left{
        width: 660px;
    }
    .right{
        width: 500px;
        .slidecapsfasility{
            .list{
                display: table; min-height: 490px;
                .inlist{display: table-cell; vertical-align: middle;}
            }
        }
    }
}
.slidefasility{
    @include boxShadow(0 10px 50px 0 rgba(0,0,0,0.2)); @include borderRadius(10px); 
    .list{
        figure{
            img{@include borderRadius(10px);}
        }
    }
    .slick-dots{
        position: absolute; top: 100%; right: 80px; margin-top: 20px;
        li{
            float: left; margin: 0 0 10px; margin-right: 27px;
            button{font-size: 0; color: transparent; background: $brown; border: 3px solid $brown; @include borderRadius(6px); padding: 0; width: 14px; height: 14px; cursor: pointer;}

            &:last-child{margin-right: 0;}

            &.slick-active{
                button{background: transparent;}
            }
        }
    }
}

.slidecapsfasility{
    .list{
        h3{font-size: 38px; margin-bottom: 30px;}
        p{font-size: 16px; line-height: 28px; margin-bottom: 25px;}
    }
}

.sec-about{
    position: relative; padding: 140px 0 150px; min-height: 730px; @include boxSizing(border-box);
    .tgabout{position: absolute; top: 40%;}
    > figure{
        position: absolute; top: 0; right: 0; z-index: -1; left: 34%;
        img{width: 100%; height: 100%; object-fit: cover; object-position: top left;}
    }
    .desc-abouthome{
        width: 520px; float: left;
        h5{margin-bottom: 20px; text-transform: uppercase;}
        h2{line-height: 57px; margin-bottom: 35px;}
        p{line-height: 28px; color: #666666; margin-bottom: 50px;}
        .lineh{margin-bottom: 15px;}
    }
}

.sec-news{
    position: relative; padding-bottom: 105px;
    &:before{content:""; position: absolute; bottom: 0; left: 0; right: 0; top: 105px; background: #f3eae1; z-index: -1;}
    .tgnews{position: absolute; top: 40%;}
    .lg-news{
        width: 594px; float: left;
    }
    .sm-news{
        width: 600px; float: right;
        .ls-news{
            width: 282px; float: left; margin-right: 35px; background: $white; @include boxShadow(0 10px 5px 0 rgba(0,0,0,0.05));@include borderRadius(10px);
            &:last-child{margin-right: 0;}
            figure{
                position: relative;
                &:before{content: ""; position: absolute; bottom: -17px; left: 0; width: 62px; height: 62px; background: url('../images/material/plusblue.png') no-repeat center; background-size: 100%;}
                img{@include borderRadius(10px 10px 0 0); width: 100%;}
            }
            .desc{
                padding: 26px 20px 40px;
                h6{color: #111111; line-height: 28px; height: 84px; overflow: hidden; @include boxSizing(border-box); margin-bottom: 15px;}
                span{font-size: 13px; color: #999999; font-weight: 700; display: block; margin-bottom: 5px;}
            }
        }
    }
}

.slidenews{
    @include boxShadow(0 10px 5px 0 rgba(0,0,0,0.05));@include borderRadius(10px);
    .list{
        position: relative;
        figure{
            position: relative; overflow: hidden; @include borderRadius(10px);
            &:before{content: ""; position: absolute; bottom: 0; left: 0; right: 0; top: 0; background: url('../images/material/bg-btmnews.png') no-repeat bottom center; background-size: 100%; z-index: 1;}
            img{width: 100%;}
        }
        .desc{position: absolute; bottom: 0; left: 0; right: 0; padding: 0 20px 25px; z-index: 2; color: $white; font-weight: 700; font-size: 19px; line-height: 28px;}
    }
    .slick-dots{
        position: absolute; bottom: -34px;
        li{
            float: left; margin: 0; margin-right: 27px;
            button{font-size: 0; color: transparent; background: $brown; border: 3px solid $brown; @include borderRadius(6px); padding: 0; width: 14px; height: 14px; cursor: pointer;}

            &:last-child{margin-right: 0;}

            &.slick-active{
                button{background: transparent;}
            }
        }
    }
}

.content{
    padding: 130px 0 80px; position: relative;
     background: url('../images/material/bg-content-top.png') no-repeat top right, url('../images/material/bg-content-bottom.png') no-repeat bottom left;
    &:before{
        content: ""; position: absolute; top: 0; left: 0;  right: 0; bottom: 0; z-index: -1;
        background: #f5efea;
        background: -moz-linear-gradient(45deg, #f5efea 0%, #f5efea 43%, #e4c9b1 100%);
        background: -webkit-linear-gradient(45deg, #f5efea 0%,#f5efea 43%,#e4c9b1 100%);
        background: linear-gradient(45deg, #f5efea 0%,#f5efea 43%,#e4c9b1 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5efea', endColorstr='#e4c9b1',GradientType=1 );
    }
}
.ttlcontent{
    text-align: center; margin-bottom: 40px;
    h2{margin-bottom: 25px;}
    h5{margin-bottom: 20px; text-transform: uppercase;}
    .lineh{margin-bottom: 18px;}
}

.contentwp{
    h2{line-height: 1.2;}
    p{line-height: 28px; margin-bottom: 25px;}
    figure{margin-bottom: 60px;}
    ol{
        counter-reset: li;
        > li{
            padding-left: 35px; position: relative; line-height: 28px;
            &:before{
                content: counter(li); counter-increment: li; position: absolute; top: 0; left: 0;
            }
        }
    }
    ul{
        > li{
            padding-left: 35px; position: relative; line-height: 28px;
            &:before{
                content: ""; position: absolute; top: 9px; left: 10px; width: 8px; height: 8px; @include borderRadius(100%); background: $blue;
            }
        }
    }
}
.box-acc{
    .ls-acc{
        background: $white; border-bottom: 1px solid #eaeaea;z-index: 1; position: relative; @include transition(0.2s all ease-out);
        p{line-height: 28px; margin-bottom: 30px;}
        .btn-acc{
            font-size: 23px; font-weight: 700; padding: 30px 50px;border-bottom: 1px solid #eaeaea; position: relative; cursor: pointer; @include transition(0.3s all ease-out);
            .lnacc{
                position: absolute; top: 0; right: 0; bottom: 0; width: 60px;
                &:before, &:after{content: ""; width: 24px; height: 6px; background: $blue; position: absolute; top: 0; bottom: 0; margin: auto; @include transition(0.2s all ease-out);}
                &:after{@include transform(rotate(90deg));}
            }

            &:hover{background: #efefef;}
        }
        .ctn-acc{
            padding: 30px 50px 50px; display: none;
            *{word-wrap: break-word;}
        }

        &:first-child{
            @include borderRadius(10px 10px 0 0);
            .btn-acc{@include borderRadius(10px 10px 0 0);}
        }
        &:last-child{
            @include borderRadius(0 0 10px 10px);
            .btn-acc{@include borderRadius(0 0 10px 10px);}
        }

        &.active{
            margin-left: -40px; margin-right: -40px;@include borderRadius(10px); z-index: 2; @include boxShadow(0 0 80px 0 rgba(0,0,0,0.1));
            .btn-acc{
                background: #efefef; @include borderRadius(10px 10px 0 0);
                .lnacc{
                    &:after{@include transform(rotate(0deg));}
                }
            }
            .ctn-acc{display: block;@include borderRadius(0 0 10px 10px);}
        }
    }
}

.boxsliderewards{overflow: hidden;}
.sliderewards{
    .list{
        position: relative; z-index: 2; @include transition(0.2s all ease-out); @include transform(scale(1)); margin: 250px 0; @include transformOrigin(50% 50%);
        overflow: hidden; z-index: -4;
        figure{
            margin-bottom: 0; text-align: center;
            img{width: 100%; @include boxShadow(0 0 40px 0 rgba(0,0,0,0.1));}
        }
        &.slick-current{@include transform(scale(3));z-index: 2;}
        &.prev1{@include transform(scale(2.5));z-index: -1;}
        &.prev2{@include transform(scale(2));z-index: -2;}
        &.prev3{@include transform(scale(1.5));z-index: -3;}
        &.next1{@include transform(scale(2.5));z-index: -1;}
        &.next2{@include transform(scale(2));z-index: -2;}
        &.next3{@include transform(scale(1.5));z-index: -3;}
    }

    &.minseven{
        .list{
            margin-top: 200px; margin-bottom: 200px;
            &.slick-current{@include transform(scale(2));z-index: 2;}
            &.prev1{@include transform(scale(1.8));z-index: -1;}
            &.prev2{@include transform(scale(1.4));z-index: -2;}
            &.prev3{@include transform(scale(1));z-index: -3;}
            &.next1{@include transform(scale(1.8));z-index: -1;}
            &.next2{@include transform(scale(1.4));z-index: -2;}
            &.next3{@include transform(scale(1));z-index: -3;}
        }
    }
    &.minfive{
        .list{
            margin-top: 150px; margin-bottom: 150px;
            &.slick-current{@include transform(scale(1.6));z-index: 2;}
            &.prev1{@include transform(scale(1.4));z-index: -1;}
            &.prev2{@include transform(scale(1.2));z-index: -2;}
            &.prev3{@include transform(scale(1));z-index: -3;}
            &.next1{@include transform(scale(1.4));z-index: -1;}
            &.next2{@include transform(scale(1.2));z-index: -2;}
            &.next3{@include transform(scale(1));z-index: -3;}
        }
    }
    &.minfour{
        .list{
            margin-top: 150px; margin-bottom: 150px;
            &.slick-current{@include transform(scale(1.2));z-index: 2;}
            &.prev1{@include transform(scale(1.1));z-index: -1;}
            &.prev2{@include transform(scale(1));z-index: -2;}
            &.prev3{@include transform(scale(1));z-index: -3;}
            &.next1{@include transform(scale(1.1));z-index: -1;}
            &.next2{@include transform(scale(1));z-index: -2;}
            &.next3{@include transform(scale(1));z-index: -3;}
        }
    }
    &.minthree{
        .list{
            margin-top: 150px; margin-bottom: 150px;
            figure{
                text-align: center;
                img{width: auto; display: inline-block;}
            }
            &.slick-current{@include transform(scale(1.05));z-index: 2;}
            &.prev1{@include transform(scale(1));z-index: -1;}
            &.prev2{@include transform(scale(1));z-index: -2;}
            &.prev3{@include transform(scale(1));z-index: -3;}
            &.next1{@include transform(scale(1));z-index: -1;}
            &.next2{@include transform(scale(1));z-index: -2;}
            &.next3{@include transform(scale(1));z-index: -3;}
        }
    }
    &.minduo{
        .list{
            margin-top: 50px; margin-bottom: 50px;
            figure{
                img{width: auto; display: inline-block; max-height: 500px;}
            }
            &.slick-current{@include transform(scale(1));z-index: 2;}
            &.prev1{@include transform(scale(1));z-index: -1;}
            &.prev2{@include transform(scale(1));z-index: -2;}
            &.prev3{@include transform(scale(1));z-index: -3;}
            &.next1{@include transform(scale(1));z-index: -1;}
            &.next2{@include transform(scale(1));z-index: -2;}
            &.next3{@include transform(scale(1));z-index: -3;}
        }
    }
}
.slidecapsrewards{
    width: 770px; margin: 0 auto 200px; position: relative;
    &:before{content: ""; position: absolute; bottom: -90px; height: 66px; width: 2px; left: 0; right: 0; margin: 0 auto; background: #e6ceb9;}
    .list{
        text-align: center;color: #666666;
        h4{font-weight: 700; margin-bottom: 20px; text-transform: uppercase;}
        p{font-size: 16px;}
    }

    .slick-prev, .slick-next{
        position: absolute; bottom: -77px; left: 50%; @include transform(translateX(-50%));padding: 0; font-size: 0; color: transparent; border: none; width: 40px; height: 40px; border: 2px solid $orange; @include borderRadius(100%);
        cursor: pointer; @include transition(0.2s all ease-out);
    }
    .slick-prev{
        background: url('../images/material/prev-slide.png') no-repeat center; margin-left: -35px;
        &:hover{margin-left: -40px;}
    }
    .slick-next{
        background: url('../images/material/next-slide.png') no-repeat center; margin-left: 35px;
        &:hover{margin-left: 40px;}
    }
}
.milestoneimg{
    img{@include borderRadius(10px); @include boxShadow(0 0 50px -5px rgba(0,0,0,0.1));}
}

.servicerow{
    .ls-servicerow{
        margin-bottom: 120px;
        @include afterboth;
        h5{text-transform: uppercase; color: $blue;}
        /*p{max-height: 112px; overflow: hidden;}*/
        .ttlservicerow{
            position: relative; padding-top: 0; padding-bottom: 20px; padding-left: 52px; margin-bottom: 25px;
            &:before{content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 4px; background: $orange;}
            .icwp{position: absolute; top: 0; left: 3px; bottom: 0;}

            &.noico{padding-left: 0;}
        }
        .desc{float: left; width: 40%; padding-top: 40px;}
        .img{
            float: right; width: 53%; text-align: right;
            figure{
                margin: 0 0 28px;
                img{width: 100%; @include borderRadius(10px); @include boxShadow(0 5px 50px 0 rgba(0,0,0,0.15));}
            }
        }
        &:nth-child(even){
            text-align: right;
            .ttlservicerow{
                padding-left: 0; padding-right: 52px;
                .icwp{left: auto; right: 0;}

                &.noico{padding-right: 0;}
            }
            .desc{float: right;}
            .img{float: left; text-align: left;}
        }
    }
}

.slidecontent{
    margin-bottom: 90px; @include boxShadow(0 0 50px -5px rgba(0,0,0,0.1));@include borderRadius(10px);
    .list{
        figure{
            margin: 0;
            img{@include borderRadius(10px);@include borderRadius(10px);}
        }
    }
    .slick-dots{
        position: absolute;bottom: -50px; left: 50%; @include transform(translateX(-50%));
        li{
            float: left; margin: 0; margin-right: 27px;padding: 0;
            &:before{display: none;}
            button{font-size: 0; color: transparent; background: $blue; border: 3px solid $blue; @include borderRadius(7px); padding: 0; width: 17px; height: 17px; cursor: pointer;}

            &:last-child{margin-right: 0;}

            &.slick-active{
                button{background: transparent;}
            }
        }
    }
}

.box-spesialis{
    border-top: 1px solid #bcb2aa; padding-top: 93px;
    @include afterboth;
    .left{width: 660px;}
    .right{width: 475px; padding-top: 70px;}
}

.slidespesialis{
    figure{
        img{@include borderRadius(10px);}
    }
}
.slidecapsspesialis{
    position: relative;
    &:before{content: ""; position: absolute; bottom: -90px; height: 66px; width: 2px; left: 58px; margin: 0 auto; background: #e6ceb9;}
    h3{margin-bottom: 30px;}
    p{max-height: 170px; overflow: hidden;}
    .list{padding-bottom: 50px;}
    .slick-prev, .slick-next{
        position: absolute; bottom: -80px; padding: 0; font-size: 0; color: transparent; border: none; width: 40px; height: 40px; border: 2px solid $orange; @include borderRadius(100%);
        cursor: pointer; @include transition(0.2s all ease-out);
    }
    .slick-prev{
        background: url('../images/material/prev-slide.png') no-repeat center; left: 0;
        &:hover{left: -5px;}
    }
    .slick-next{
        background: url('../images/material/next-slide.png') no-repeat center; left: 77px;
        &:hover{left: 82px;}
    }
}

.box-toplist{
    border-top: 1px solid #bcb2aa; padding-top: 93px;
}
.classlist{
    margin: 0 -27px 80px; position: relative; padding-bottom: 0;
    @include afterboth;
    .ls-classlist{
        float: left; padding: 0 27px 40px; width: 25%; @include boxSizing(border-box); position: relative;
        figure{
            margin: 0; position: relative;
            &:before{content: ""; position: absolute; bottom: -40px; left: 30px; border-bottom: 18px solid $brown; border-left: 15px solid transparent; border-right: 15px solid transparent; opacity: 0; visibility: hidden; @include transition(0.2s all ease-out);}
            &:after{content: ""; position: absolute; bottom: 0; left: 0; top: 0; right: 0; background: rgba(0,0,0,0.4); z-index: 2;@include borderRadius(10px);}
            img{@include borderRadius(10px); width: 100%;}
            .caps{
                position: absolute; bottom: 0; left: 0; right: 0; z-index: 3; color: $white; padding: 20px;
                h6{float: left; vertical-align: bottom; display: inline-block;}
                .link-plus{float: right; vertical-align: bottom; display: inline-block;}
            }
        }
        .desc{position: absolute; top: 100%; left: 0; width: 400%; opacity: 0; visibility: hidden; @include transition(0.2s all ease-out); padding-left: 27px; padding-right: 27px; z-index: 10; @include boxSizing(border-box);}
        .ttlclass{
            background: $brown; @include borderRadius(10px 10px 0 0); padding: 13px 90px; font-weight: 700; font-size: 23px;
        }
        .descclass{
            background: $white; @include borderRadius(0 0 10px 10px);padding: 20px 90px 35px;
        }

        &:nth-child(4n+1){clear: both;}
        &:nth-child(4n+2){
            .desc{left: -100%;}
        }
        &:nth-child(4n+3){
            .desc{left: -200%;}
        }
        &:nth-child(4n){
            .desc{left: -300%;}
        }

        &.active{
            figure{
                &:before{opacity: 1; visibility: visible;}
            }
            .desc{opacity: 1; visibility: visible;}
        }
        &:hover{
            figure{
                &:before{opacity: 1; visibility: visible;}
            }
            .desc{opacity: 1; visibility: visible;}
        }
    }
}
.tanyadokter{
    padding-bottom: 540px;
    .intanyadokter{position: relative; z-index: 2;}
    .fbtanyadokter{
        position: absolute; bottom: 0; margin: 0 !important; padding-top: 320px; background: url('../images/material/bg-line2.png') no-repeat bottom center;
    }
}

.box-lsdokter{
    margin-top: 96px;
    .lsdokter{
        margin-bottom: 100px;
        @include afterboth;
        h3{margin-bottom: 35px; font-size: 38px;}
        .img{
            width: 340px; float: left;
            figure{
                margin: 0;
                img{@include borderRadius(10px);}
            }
        }
        .desc{
            width: 500px; float: right; padding-top: 40px;
            .tbdiv{margin-bottom: 50px; color: #666666;}
        }
    }
}

.tbdiv{
    display: table; width: 100%;
    @include afterboth;
    > div{display: table-row; @include afterboth; }
    .lf, .rg{display: table-cell; padding-bottom: 10px;}
    .lf{width: 145px;}
    .rg{
        position: relative; padding-left: 10px;
        &:before{content: ":"; position: absolute; top: 0; left: 0;}
    }
}

.bysidebtn{
    @include afterboth;
    .left, .right{width: 50%;}
    .right{text-align: right;}
}

.detaildokter{
    h3{margin: 25px 0 30px;}
    h6{color: #666666; margin-bottom: 10px; text-transform: uppercase; font-size: 20px;}
    figure{
        margin: 0 0 34px;
        img{@include borderRadius(10px);}
    }
    p{font-size: 19px; color: #666666;}
    .bysidebtn{
        margin-bottom: 75px;font-size: 19px; color: #666666;
        a{font-size: 19px; color: #666666;}
        .right{text-align: left;}
    }

    > .left{width: 37.39%;}
    > .right{width: 56.91%;}
}

.box-roundedgrey{
    margin-bottom: 45px;
    .ttlroundedgrey{
        background: $brown; @include borderRadius(10px 10px 0 0); color: $white; padding: 20px 35px; font-weight: 700; font-size: 23px;
    }
    .descroundedgrey{
        padding: 25px 35px; background: $white;  @include borderRadius(0 0 10px 10px);
    }
}
.promolg{
    margin-bottom: 72px;
    @include afterboth;
    figure{
        margin: 0;
        img{@include borderRadius(10px);}
    }
    h3{margin-bottom: 20px; font-size: 29px;}
    span{font-weight: 700; display: block; color: #999999;font-size: 16px; margin-bottom: 10px;}
    p{margin-bottom: 30px;}
    .lineh{margin-bottom: 15px;}
    .left{width: 530px;}
    .right{width: 635px;padding-top: 28px;}
}
.promolist{
    margin: 0 -17px 85px;
    @include afterboth;
    .ls-promolist{
        width: 25%; float: left; padding: 0 17px; @include boxSizing(border-box); display: flex; flex-direction: column; position: relative;
        &:before{content: ""; position: absolute; top: 0; left: 17px; bottom: 0; right: 17px; @include boxShadow(0 10px 5px 0 rgba(0, 0, 0, 0.05)); z-index: 0; @include borderRadius(0 0 10px 10px);}
        h6{margin-bottom: 10px; line-height: 28px; height: 84px; overflow: hidden;}
        p{line-height: 28px; height: 56px; overflow: hidden;}
        span{font-weight: 700; display: block; color: #999999;font-size: 13px; margin-bottom: 10px;}
        figure{
            order: 1; margin: 0; position: relative;
            &:before{content: ""; position: absolute; left: 0; bottom: -16px; width: 62px; height: 62px; background: url('../images/material/plusblue.png') no-repeat center; background-size: 100%; z-index: 3;}
            img{@include borderRadius(10px 10px 0 0);}
        }
        .desc{
            order: 2; background: $white; @include borderRadius(0 0 10px 10px); padding: 42px 20px 30px; position: relative;
        }

        &:nth-child(4n+1){clear: both;}
        &:nth-child(even){
            padding-top: 45px;
            &:before{top: 45px;}
            figure{
                order: 2;
                &:before{bottom: auto; left: auto; right: 0; top: -16px;}
                img{@include borderRadius(0 0 10px 10px);}
            }
            .desc{order: 1;@include borderRadius(10px 10px 0 0); padding-top: 30px; padding-bottom: 42px;}
        }
    }
}

.pagination{
    text-align: center; z-index: 2; position: relative;
    @include afterboth;
    a{
        display: inline-block; width: 44px; height: 44px; line-height: 44px; background: transparent; color: #777777; font-weight: 600; margin: 0 5px; position: relative;@include transition(0.2s all ease-out); vertical-align: middle;
        &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; right: 0; background: $blue; opacity: 0; @include transform(scale(0)); z-index: -1; @include borderRadius(5px); @include transition(0.2s all ease-out);}
        &.prev, &.next{
            @include borderRadius(100%); border: 1px solid #acacac; position: relative; width: 38px; height: 38px;
            span{
                position: absolute; top: 0; left: 0; right: 0; bottom: 0;
                &:before, &:after{content: ""; display: block; width: 13px; height: 4px; background: $blue; position: absolute; top: 0; left: 0; bottom: 0; right: 0; margin: auto; @include transition(0.2s background ease-out);}
            }
            &:before{display: none;}

            &:hover{
                background: $blue;
                span{
                    &:before, &:after{background: $white;}
                }
            }
        }
        &.next{
            span{
                &:after{@include transform(rotate(90deg));}
            }
        }
        
        &:hover, &.active{
            color: $white;
            &:before{opacity: 1; @include transform(scale(1));}
        }
        &.active{
            margin: 0 18px; font-weight: 700;
        }
    }
}

.box-bysidecontent{
    margin-bottom: 100px;
    @include afterboth;
    .lineh{margin-bottom: 10px;}
    h2{margin-bottom: 20px;}
    h5{margin-bottom: 10px;}
    p{margin-bottom: 30px;}
    figure{
        margin: 0;
        img{@include borderRadius(5px); @include boxShadow(0 0 30px 0 rgba(0,0,0,0.1));}
    }
    .left{width: 24.23%;}
    .right{width: 70.8%; padding-top: 15px;}

    &.margtop{margin-top: 125px;}
}

.mapcontact{
    margin: 30px -50px -30px; @include borderRadius(0 0 10px 10px); overflow: hidden;
    .map_contact{height: 445px;@include borderRadius(0 0 10px 10px); overflow: hidden;}
}
.frm-side{
    position: relative; min-height: 500px;
    @include afterboth;
    figure{margin: 0;}
    h2{margin-bottom: 20px; height: 120px; overflow: hidden;}
    h5{margin-bottom: 15px;}
    p{line-height: 28px; color: #666666; height: 84px; overflow: hidden;}
    .left{
        width: 57.26%; padding-top: 95px;
        .lineh{margin-bottom: 15px;}
    }
    .right{width: 47.777777777777%; position: absolute; bottom: -80px; right: -5%;}
}

.boxslidemilestone{
}

.dotsmilestone{
    position: relative;margin-bottom: 40px;
    &:before{content: ""; position: absolute; bottom: 5px; left: 0; right: 0; height: 2px; background: $blue; z-index: 1;}
    .slick-list{z-index: 2;}
    .list{
        position: relative; text-align: center; padding-bottom: 20px; font-size: 13px; cursor: pointer; height: 39px; @include boxSizing(border-box);
        &:before{content: ""; position: absolute; bottom: 0; left: 0; right: 0; margin: 0 auto; width: 12px; height: 12px; border: 3px solid $blue; @include borderRadius(100%); @include boxSizing(border-box); background: $white;}

        &.slick-current{
            font-weight: 700; font-size: 19px; color: $orange;
            &:before{background: $blue;}
        }
    }
    .slick-prev, .slick-next{
        position: absolute; bottom: -10px; font-size: 0; color: transparent; background: transparent; border: 2px solid $orange; padding: 0; @include borderRadius(100%); width: 35px; height: 35px; cursor: pointer;
        @include transition(0.2s all ease-out);
        &.slick-disabled{opacity: 0.5;}
    }
    .slick-prev{
        left: -60px; background: url('../images/material/prev-slide.png') no-repeat center;
        &:hover{left: -65px;}
    }
    .slick-next{
        right: -60px; background: url('../images/material/next-slide.png') no-repeat center;
        &:hover{right: -65px;}
    }
}
.capsmilestone{
    h2{margin-bottom: 15px;}
    h5{margin-bottom: 10px;}
    .lineh{margin-bottom: 15px;}
}

.box-slide-detail{
    .leftcapsbeauty{
        width: 435px; float: left; border-right: 1px solid #e5cdb8; padding-top: 20px; @include boxSizing(border-box); padding-right: 20px;
        .ttlrescaps{display: none;}
    }
    .slideimgdetail{width: 870px; float: right;}
}
.box-capsbeauty{
    .btncapsbeauty{display: none;}
}
.capsbeauty{
    .list{
        font-size: 16px; font-weight: 700; color: #333333; line-height: 24px; margin-bottom: 25px; position: relative; cursor: pointer; @include transition(0.2s all ease-out);
        &:before{content: ""; width: 8px; height: 8px; background: $blue; @include borderRadius(100%); position: absolute; top: 8px; left: 10px; opacity: 0; visibility: hidden; @include transition(0.2s all ease-out);}
        &:hover{color: $orange;}
        &.active{
            color: $orange; font-size: 19px; padding-left: 42px;
            &:before{opacity: 1; visibility: visible; left: 18px;}
        }
    }
}
.slideimgdetail{
    position: relative;
    &:before{content: ""; position: absolute; bottom: 100px; right: 0; width: 66px; height: 2px; background: #e6ceb9;}
    figure{margin-bottom: 40px;}
    h5{margin-bottom: 15px;}
    .desc{padding-right: 170px; min-height: 140px;}

    .slick-prev, .slick-next{
        font-size: 0; color: transparent; padding: 0; width: 40px; height: 40px; border: 2px solid $orange; @include transform(rotate(90deg)); @include borderRadius(100%); cursor: pointer;
        position: absolute; right: 13px;  z-index: 2; @include transition(0.2s all ease-out);
        &.slick-disabled{opacity: 0.5;}
    }
    .slick-prev{
        background: url('../images/material/prev-slide.png') no-repeat center; bottom: 120px;
        &:hover{bottom: 125px;}
    }
    .slick-next{
        background: url('../images/material/next-slide.png') no-repeat center; bottom: 43px;
        &:hover{bottom: 38px;}
    }
}

.grid-accordion{
    background: $white; padding: 20px; @include borderRadius(10px); margin-bottom: 100px;
    h4{font-weight: 700;}
    p{height: 56px; overflow: hidden;}
    .ga-panel{
        @include borderRadius(10px);
        &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4);visibility: visible; opacity: 1; @include transition(0.5s all ease-out); z-index: 2;}
        > a{
            display: block; position: relative;
            &:before{content: ""; background: url('../images/material/fg-fasilitas.png') no-repeat bottom center; background-size: 100%; position: absolute; top: 0; left: 0; bottom: 0; right: 0; opacity: 0;}
        }
        img{@include borderRadius(10px);}

        &.ga-opened{
            &:before{opacity: 0; visibility: hidden;}
            > a{
                &:before{opacity: 1;}
            }
        }
    }
    .ttl-named{
        font-size: 23px; font-weight: 700; line-height: 36px; padding: 24px 20px;
    }
    .ga-layer{
        z-index: 2;color: $white; 
        &.ga-padding{padding: 0 50px 0;}
        /*&.ga-white{background: transparent;}*/
    }

    .ga-pagination-button{
        width: 17px; height: 17px; @include borderRadius(7px); border: 3px solid $blue; background: $blue; margin: 0 12px;
    }
    .ga-pagination-buttons{
        padding-top: 40px;
        .ga-selected{background: transparent;}
    }
}


/* footer
----------------------------------------------------------------------------------------------*/
.box-address{
    font-size: 14px; line-height: 23px;
    @include afterboth;
    a{color: $white; display: block;}
    .lf, .rg{width: 50%; @include boxSizing(border-box);}
    .lf{float: left; font-weight: 700; padding-right: 10px;}
    .rg{float: right; padding-left: 10px;}

    &.addresslg{
        font-size: 19px; line-height: 28px;
        a{color: #333;}
        p{margin-bottom: 0;}
        .lf{font-weight: 400; width: 250px; margin-right: 40px;}
        .rg{font-size: 16px; width: 230px; float: left;}
    }
}
.box-sosmed{
    font-size: 13px;
    label{margin-right: 15px;}
    a{
        display: inline-block; width: 30px; height: 30px; background-repeat: no-repeat; background-size: 100%; background-position: center; vertical-align: middle; margin-right: 5px;
        &.fb{background-image: url('../images/material/ic_fb.png');}
        &.tw{background-image: url('../images/material/ic_tw.png');}
        &.ig{background-image: url('../images/material/ic_ig.png');}
    }
}

table{
    &.table-doctor{
        width: 100%; @include borderRadius(5px); overflow: hidden; background: $white; font-size: 14px; line-height: 24px;
        th, td{padding: 15px 20px; text-align: left; vertical-align: top;}
        thead{
            th, td{
                padding: 20px; font-size: 16px; font-weight: 700; vertical-align: middle;
                &:last-child{text-align: center;}
            }
            tr{background: $orange; color: $white;}
        }
        h6{margin-bottom: 5px;}
        span{display: block; font-weight: 700; margin-bottom: 20px;}
        .btn-rounded{margin-bottom: 10px;}
    }
}

.box-schedule{
    .ls-schedule{
        .lf, .rg{width: 48%;}
        .lf{float: left; clear: both; font-weight: 700;}
        .rg{float: right; text-align: right;}
    }
}

footer{
    .foot-top{
        position: relative; color: $white;
        &:before{content: ""; position: absolute; top: 0; left: 0; bottom: 0; width: 50%; background: #ceac82; z-index: -1;}
        &:after{content: ""; position: absolute; top: 0; right: 0; bottom: 0; width: 50%; background: #c4975f; z-index: -1;}
        h6{margin-bottom: 17px;}
        .left, .right{width: 470px;}
        .left{padding: 20px 0 20px 29px;}
        .right{padding: 20px 29px 20px 0;}
    }
    .foot-bottom{
        background: #524b49; color: $white; padding: 22px 0;
        a{color: $white;}
        ul{
            float: left; padding-top: 25px; margin-right: 15px;
            li{
                float: left; margin-right: 22px; font-size: 13px;
                &:last-child{margin-right: 0;}
            }
        }
        .logo-foot{float: left; margin-right: 50px;}
        .centerfoot{float: left;}
        .rightfoot{float: right;}
        .box-inquiry{float: left; padding-top: 13px; margin-right: 20px;}
        .box-sosmed{
            float: right; padding-top: 18px;
        }
    }
    .copyrights{background: #333333; text-align: center; padding: 24px 0; text-transform: uppercase; font-weight: 600; font-size: 13px; color: rgba(255,255,255,0.15);}
}